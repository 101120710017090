





















import Vue from 'vue';
import Component from 'vue-class-component';
import { ILanguage } from '@/store/types/ILanguage';
import UserSettingsModule from '@/store/modules/UserSettingsModule';
import AuthModule from '@/store/modules/AuthModule';
import UserAccountModule from '@/store/modules/UserAccountModule';

@Component
export default class LanguageSelector extends Vue {
  get languages() {
    return UserSettingsModule.languages;
  }

  get isAuthenticated() {
    return AuthModule.isAuthenticated;
  }

  get selectedLanguage() {
    return UserSettingsModule.languageByCode(
      AuthModule.profile?.PreferredLanguage as string,
    );
  }

  async setLanguage(language: ILanguage) {
    await UserAccountModule.changePreferredLanguage(language.code);
  }
}
