




















































































































































































import { Component, Vue } from 'vue-property-decorator';
import AuthModule from '@/store/modules/AuthModule';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import * as api from '@/store/api';
import languageselector from '@/pages/settings/components/languageselector.vue';

@Component({
  components: {
    languageselector,
  },
})
export default class AccountSettingsPage extends Vue {
  created() {
    //we refresh the username and account so that if the user changes their
    //email or username it gets refreshed
    AuthModule.refreshUser();
  }

  resetAccount() {
    this.$bvModal
      .msgBoxConfirm(
        this.$t('TL_SHARED_RESET_ACCOUNT_ARE_YOU_SURE').toString(),
        {
          title: this.$t('TL_SHARED_RESET_ACCOUNT').toString(),
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: this.$t('TL_DIALOG_YES').toString(),
          cancelTitle: this.$t('TL_DIALOG_NO').toString(),
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true,
        },
      )
      .then((value) => {
        if (value) {
          ExplorerDataModule.resetAccount().then(() => {
            this.$bvModal.msgBoxOk(
              this.$t(
                'TL_DIALOG_YOUR_ACCOUNT_HAS_BEEN_RESET',
              ).toString(),
              {
                title: this.$t(
                  'TL_SHARED_RESET_ACCOUNT_SUCCESS',
                ).toString(),
                size: 'md',
                buttonSize: 'md',
                okVariant: 'danger',
                okTitle: this.$t('TL_DIALOG_OK').toString(),
                footerClass: 'p-2',
                hideHeaderClose: true,
                centered: true,
              },
            );
          });
        }
      })
      .catch((err) => {
        // An error occurred
      });
  }

  resetDeletedMemory() {
    this.$bvModal
      .msgBoxConfirm(
        this.$t(
          'TL_SHARED_RESET_DELETED_MEMORY_ARE_YOU_SURE',
        ).toString(),
        {
          title: this.$t('TL_SHARED_RESET_DELETED_MEMORY').toString(),
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: this.$t('TL_DIALOG_YES').toString(),
          cancelTitle: this.$t('TL_DIALOG_NO').toString(),
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true,
        },
      )
      .then((value) => {
        if (value) {
          api.resetDeletedMemory().then(() => {
            this.$bvModal.msgBoxOk(
              this.$t(
                'TL_DIALOG_YOUR_DELETED_MEMORY_HAS_BEEN_RESET',
              ).toString(),
              {
                title: this.$t(
                  'TL_SHARED_RESET_DELETED_MEMORY_SUCCESS',
                ).toString(),
                size: 'md',
                buttonSize: 'md',
                okVariant: 'danger',
                okTitle: this.$t('TL_DIALOG_OK').toString(),
                footerClass: 'p-2',
                hideHeaderClose: true,
                centered: true,
              },
            );
          });
        }
      })
      .catch((err) => {
        // An error occurred
      });
  }

  get userProfile() {
    return AuthModule.profile;
  }
}
